import { PrizeType } from '@longnecktech/splash-commons-fe';
import { CurrencyService } from '@services/currency.service';
import { QuizPrize } from '@shared/types/prize-package';

export function overridePrizeLabel(
  prize: QuizPrize,
  currencyService: CurrencyService,
): string {
  const cashAmount = currencyService.getValueWithCurrency(
    prize.value,
    prize.currency,
  );
  if (prize.type === PrizeType.CASH) {
    return cashAmount;
  } else if (prize.type === PrizeType.CUSTOM) {
    return prize.label.replace('{{value}}', cashAmount);
  } else {
    return prize.label;
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@services/translation.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private snack: MatSnackBar,
    private translationService: TranslationService,
  ) {}

  showNotification(message: string, duration = 3000): void {
    this.snack.open(this.translationService.translate(message), 'OK', {
      duration,
    });
  }
}

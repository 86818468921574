import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from '@services/session.service';
import { Observable, of } from 'rxjs';
import { environment } from '@environment';
import { UserAction } from '@longnecktech/splash-commons-fe';

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  constructor(
    private session: SessionService,
    private http: HttpClient,
  ) {}

  sendAction(action: UserAction, metadata: unknown): Observable<void> {
    return this.http.post<void>(environment.backendUrl + '/api/user/action', {
      action,
      metadata,
      gameUuid: this.session.game()?.uuid,
    });
  }

  saveUuidToLocalStorage(uuid: string, daysValid = 7) {
    // 7 days in milliseconds
    const expirationTime = Date.now() + daysValid * 24 * 60 * 60 * 1000;
    const signupData = { uuid, expiration: expirationTime };
    localStorage.setItem('signup_uuid', JSON.stringify(signupData));
  }

  getSignupUuid(): string | null {
    const signupData = localStorage.getItem('signup_uuid');
    if (!signupData) return null;

    const parsedData = JSON.parse(signupData);
    if (parsedData.expiration < Date.now()) {
      localStorage.removeItem('signup_uuid');
      return null;
    }
    return parsedData.uuid;
  }

  trackSignupConfirmed(): Observable<void> {
    const signupUuid = this.getSignupUuid();
    if (signupUuid) {
      localStorage.removeItem('signup_uuid');
      return this.sendAction(UserAction.SIGNUP_CONFIRMED, { uuid: signupUuid });
    }
    return of(undefined);
  }
}

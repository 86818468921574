import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { SessionService } from '@services/session.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private session: SessionService,
  ) {}

  getCurrencyFormat(language?: string): string {
    const lang = this.getLanguage(language);
    switch (lang) {
      default:
        return '1.0-2';
    }
  }

  getCurrencyDisplay(currency?: string): string {
    // code, symbol-narrow, symbol or custom string
    switch (currency?.toUpperCase()) {
      case 'XAF':
      case 'SLE':
        return 'code';
      case 'SLL':
        return 'Se';
      case 'LRD':
        return 'L$';
      default:
        return 'symbol-narrow';
    }
  }

  getLanguage(lang?: string): string {
    if (!lang) return 'en';
    return TranslationService.supportedLanguages.includes(lang) ? lang : 'en';
  }

  getValueWithCurrency(value: number, currency: string): string {
    return (
      this.currencyPipe.transform(
        value,
        currency,
        this.getCurrencyDisplay(currency),
        this.getCurrencyFormat(this.session.user()?.language),
        this.getLanguage(this.session.user()?.language),
      ) || ''
    );
  }
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '@environment';
import { overridePrizeLabel } from '@shared/utils/prize-label.utils';
import { SessionService } from './session.service';
import { Game } from '@shared/types/game';
import {
  Transaction,
  TransactionLine,
  LinePick,
} from '@shared/types/transaction';
import {
  tap,
  Observable,
  catchError,
  BehaviorSubject,
  take,
  throwError,
  map,
  of,
} from 'rxjs';
import { Page, UserAction } from '@longnecktech/splash-commons-fe';
import { Theme, ConfigValue } from '@shared/types/theme';
import { CurrencyService } from './currency.service';
import { PrizePackage } from '@shared/types/prize-package';
import { PicksRequest } from '@shared/types/submission-resource';
import { QuestionType, Question } from '@shared/types/question';
import { NotificationService } from '@services/notification.service';
import { Leaderboard } from '@shared/types/leaderboard';
import { QuestionOption } from '@shared/types/question-option';
import {
  DeepLinkingRequest,
  DeepLinkingResponse,
} from '@shared/types/deeplinking';
import { UserActionService } from './user-action.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private _isLoading = new BehaviorSubject<boolean>(false);

  isLoading$ = this._isLoading.asObservable();

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private currencyService: CurrencyService,
    private notificationService: NotificationService,
    private userActionService: UserActionService,
  ) {}

  fetchGame(): Observable<Game> {
    return this.http
      .get<Game>(
        `${environment.backendUrl}/api/quiz/game?${this.getQueryParams()}`,
      )
      .pipe(
        tap((data: Game) => {
          this.session.setCurrentGame({
            ...data,
            leaderboard: this.getLeaderboard(data.leaderboard),
            prizePackage: this.getUpdatedPackage(data.prizePackage),
            questions: data.questions
              .map((question) => {
                if (question.type === QuestionType.CorrectScore) {
                  return {
                    ...question,
                    competitor1Score: 0,
                    competitor2Score: 0,
                  };
                }
                return question;
              })
              .sort((a, b) => a.index - b.index),
          });
          this.injectCSSVariablesIntoDOM(data.theme);
        }),
      );
  }

  fetchTransactions(page: number) {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    return this.http
      .get<Page<Transaction>>(
        `${environment.backendUrl}/api/quiz/transaction/history`,
        {
          params,
        },
      )
      .pipe(
        tap((data) => {
          if (data.content.length > 0) {
            this.session.updateHasResult(true);
          }
          data.content.forEach((transaction) => {
            if (transaction.quizGame.uuid === this.session.game()!.uuid) {
              this.session.hasCurrentGameResults = true;
              this.session.removeGameFromStorage();
            }
          });
        }),
        map((value) => {
          if (value.empty) return value;
          value.content?.[0]?.quizLines.sort(
            (a: { lineIndex: number }, b: { lineIndex: number }) =>
              a.lineIndex - b.lineIndex,
          );
          value.content?.[0]?.quizLines.forEach((l: TransactionLine) =>
            l.picks.forEach(
              (p: LinePick) =>
                (p.option = this.getOption(value.content?.[0]?.quizGame, p)),
            ),
          );
          value.content?.[0]?.quizLines.forEach((l: TransactionLine) =>
            l.picks.sort(
              (a: LinePick, b: LinePick) =>
                (a.option?.index || 0) - (b.option?.index || 0),
            ),
          );
          // add correct score value from quiz lines to quiz game
          // so that we can display it in My Picks section
          const allPicks = value.content?.[0]?.quizLines.reduce((acc, line) => {
            return [...acc, ...line.picks];
          }, [] as LinePick[]);
          value.content?.[0].quizGame.questions.forEach(
            (question: Question) => {
              if (question.type === QuestionType.CorrectScore) {
                const questionPick = allPicks.find(
                  (pick) => pick.questionUuid === question.uuid,
                );
                question.points = questionPick?.points ?? 0;
                question.competitor1Score = questionPick?.competitor1Score ?? 0;
                question.competitor2Score = questionPick?.competitor2Score ?? 0;
              }
            },
          );
          return { ...value };
        }),
      );
  }

  submitPicks(questions?: Question[]): Observable<void | null> {
    if (!questions) return of(null);
    this._isLoading.next(true);
    const picks = this.getPicks(questions);
    return this.http
      .post<void>(
        environment.backendUrl +
          `/api/quiz/pick/v2/${this.session.game()?.uuid}`,
        picks,
      )
      .pipe(
        tap(() => {
          this.session.updateDataOnSubmittingPicks();
          this._isLoading.next(false);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      );
  }

  updatePicks(
    transaction: Transaction,
    questions?: Question[],
  ): Observable<void | null> {
    if (!questions) return of(null);
    this._isLoading.next(true);
    const picks = this.getPicks(questions);
    return this.http
      .put<void>(
        environment.backendUrl + `/api/quiz/pick/v2/${transaction.uuid}`,
        picks,
      )
      .pipe(
        tap(() => {
          this.session.updateDataOnSubmittingPicks();
          this._isLoading.next(false);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      );
  }

  sendBets(request: DeepLinkingRequest): Observable<DeepLinkingResponse> {
    this._isLoading.next(true);
    this.userActionService
      .sendAction(UserAction.CLICK_DEEPLINKING, {
        bets: request.Bets,
      })
      .pipe(take(1))
      .subscribe();

    return this.http
      .post<DeepLinkingResponse>(
        environment.backendUrl + '/api/quiz/deeplinking',
        request,
      )
      .pipe(
        tap(() => this._isLoading.next(false)),
        catchError((err) => {
          this._isLoading.next(false);
          this.notificationService.showNotification('deeplink.failed');
          return throwError(() => new Error(err.message));
        }),
      );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const message =
      err.status === 400 && err.error.message
        ? err.error.message
        : 'Error while submitting picks';
    this.notificationService.showNotification(message);
    this._isLoading.next(false);
    this.userActionService
      .sendAction(UserAction.TRANSACTION_ERROR, {
        when: 'submitting picks',
        error: message,
      })
      .pipe(take(1))
      .subscribe();
    return throwError(() => new Error(err.message));
  }

  getLinesForGame(gameUuid: string) {
    return this.http
      .get<Transaction>(
        environment.backendUrl + `/api/quiz/game/${gameUuid}/transaction`,
      )
      .pipe(tap((transaction) => this.session.transaction.set(transaction)));
  }

  private getLeaderboard(leaderboard: Leaderboard | null): Leaderboard | null {
    if (!leaderboard) return null;
    return {
      ...leaderboard,
      prizePackage: {
        ...leaderboard.prizePackage,
        prizes: leaderboard.prizePackage.prizes
          .map((prize) => ({
            ...prize,
            prize: {
              ...prize.prize,
              label: overridePrizeLabel(prize.prize, this.currencyService),
            },
          }))
          .slice()
          .sort((a, b) => a.score - b.score),
      },
    };
  }

  private getUpdatedPackage(prizePackage: PrizePackage): PrizePackage {
    if (prizePackage.prizes) {
      const updatedPrizes = prizePackage.prizes.map((prize) => {
        if (typeof prize.prize.value === 'number' && prize.prize.currency) {
          return {
            ...prize,
            prize: {
              ...prize.prize,
              label: overridePrizeLabel(prize.prize, this.currencyService),
            },
          };
        } else {
          return prize;
        }
      });

      return {
        ...prizePackage,
        prizes: updatedPrizes.sort((a, b) => b.score - a.score),
      };
    }

    return prizePackage;
  }

  private getPicks(questions: Question[]): PicksRequest {
    return questions.reduce((acc, question) => {
      if (question.type === QuestionType.Options) {
        const optionUuids = question.options
          .filter((option) => option.picked)
          .map((option) => option.uuid);
        if (optionUuids.length) {
          acc.push({
            questionUuid: question.uuid,
            optionUuids,
          });
        }
      }

      if (question.type === QuestionType.CorrectScore) {
        acc.push({
          questionUuid: question.uuid,
          competitor1Score: question.competitor1Score,
          competitor2Score: question.competitor2Score,
        });
      }
      return acc;
    }, [] as PicksRequest);
  }

  private hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  private injectCSSVariablesIntoDOM(theme: Theme) {
    const styleEl = document.createElement('style');

    const fonts = `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }

      @font-face {
        font-family: Headlines;
        src: url('${
          theme.jackpotFontAsset?.url
            ? theme.jackpotFontAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }
    `;

    styleEl.textContent = `:root {
      ${theme.cssVariables
        .filter((v) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = this.hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
    }
    ${fonts}
    `;
    document.head.appendChild(styleEl);
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }

  private getOption(game: Game, pick: LinePick): QuestionOption | undefined {
    const question = game.questions.find(
      (question) => question.uuid === pick.questionUuid,
    );
    if (!question || question.type === QuestionType.CorrectScore) return;
    return question.options.find((option) => option.uuid === pick.pickedUuid)!;
  }
}
